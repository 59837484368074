import { environmentBase, mergeEnviroments } from './environment.base';

export const environment = {
	production: true,
	...mergeEnviroments(environmentBase, {
		URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',
		HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',
		HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
		HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',
		HOST_EMISION_ELECTRONICA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-emision-electronica/api',
		HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',
	}),
};
